import axios, { type AxiosInstance } from 'axios'
import * as Sentry from '@sentry/vue'
import { Auth0Wrapper } from '~/utils/core/auth0-wrapper'
import { Auth0Exception } from '~/exceptions/auth0.exception'

export default defineNuxtPlugin({
  name: 'axios-plugin',
  parallel: true,
  dependsOn: ['auth0-plugin', 'sentry-plugin'],
  setup(nuxtApp) {
    const { API_URL } = nuxtApp.$config.public
    const auth0Wrapper = nuxtApp.$auth as Auth0Wrapper
    const auth0Client = auth0Wrapper.client

    const axiosClient: AxiosInstance = axios.create({
      baseURL: API_URL,
    })

    axiosClient.interceptors.request.use(
      async (config) => {
        try {
          const token = await auth0Client.getTokenSilently()
          if (token) {
            config.headers.Authorization = `Bearer ${token}`
          }
          return config
        } catch (err) {
          const authError = new Auth0Exception('Error getting token from cache', err)
          return Promise.reject(authError)
        }
      },
      (error) => Promise.reject(error),
    )

    axiosClient.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config

        if (error instanceof Auth0Exception) {
          await auth0Wrapper.logout()
        } else if (
          error.response &&
          navigator.onLine &&
          error.response.status === 401 &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true

          try {
            await auth0Client.getTokenSilently({ cacheMode: 'off' })
            const newToken = await auth0Client.getTokenSilently()

            if (newToken) {
              originalRequest.headers.Authorization = `Bearer ${newToken}`
              return axiosClient(originalRequest)
            }
          } catch (_) {
            await auth0Wrapper.logout()
          }
        } else if (
          error.response &&
          navigator.onLine &&
          error.response.status === 401 &&
          originalRequest._retry
        ) {
          await auth0Wrapper.logout()
        } else if (error.request && !navigator.onLine) {
          return Promise.reject(
            new Error(
              "Sorry, but we're unable to process your request as there is no internet connection. Please ensure you are connected to a network before trying again.",
            ),
          )
        } else {
          Sentry.captureException(error)
          return Promise.reject(
            new Error(
              "An unexpected error has occurred. If this issue persists, please don't hesitate to reach out to our support team for assistance.",
            ),
          )
        }
      },
    )

    return {
      provide: {
        axios: axiosClient,
      },
    }
  },
})
