import { defineNuxtPlugin } from 'nuxt/app'
import useAuth from '~/composables/useAuth'
import { Authorization } from '~/utils/core/autorization'

export default defineNuxtPlugin({
  name: 'authorization-plugin',
  parallel: true,
  dependsOn: ['auth0-plugin', 'auth0-plugin'],
  setup() {
    return {
      provide: {
        authorization: new Authorization(useAuth()),
      },
    }
  },
})
