export enum ROLES {
  ADMIN = 'Admin',
  LITHIUM_READ = 'lithium:read',
  BATTERY_CELLS_READ = 'battery-cells:read',
  ANODES_READ = 'anodes:read',
  CATHODES_READ = 'cathodes:read',
  NATURAL_GRAPHITE_READ = 'natural-graphite:read',
  LITHIUM_ADMIN = 'lithium:admin',
  BATTERY_CELLS_ADMIN = 'battery-cells:admin',
  ANODES_ADMIN = 'anode:admin',
  CATHODES_ADMIN = 'cathode:admin',
  NATURAL_GRAPHITE_ADMIN = 'natural-graphite:admin',
  STEEL_ADMIN = 'steel:admin',
  DOWNLOAD = 'download',
  DOCS_READ = 'doc:read',
  COBALT_READ = 'cobalt:read',
  COBALT_ADMIN = 'cobalt:admin',
  SYNTHETIC_GRAPHITE_READ = 'synthetic-graphite:read',
  SYNTHETIC_GRAPHITE_ADMIN = 'synthetic-graphite:admin',
  NICKEL_READ = 'nickel:read',
  NICKEL_ADMIN = 'nickel:admin',
  RARE_EARTHS_ADMIN = 'rare-earths:admin',
  LITHIUM_FORECAST_READ = 'lithium:forecast:read',
  USER_ADMIN = 'user:admin',
  BLACK_MASS_ADMIN = 'black-mass:admin',
  BLACK_MASS_READ = 'black-mass:read',
  MANGANESE_ADMIN = 'manganese:admin',
  MANGANESE_READ = 'manganese:read',
  ELECTROLYTE_ADMIN = 'electrolyte:admin',
  ELECTROLYTE_READ = 'electrolyte:read',
}
