import * as Sentry from '@sentry/vue'
import { Auth0Wrapper } from '~/utils/core/auth0-wrapper'

const getUser = (auth) => {
  return auth
    ? {
        id: auth.sub,
        email: auth.email,
        username: auth.name,
      }
    : null
}

export default defineNuxtPlugin({
  name: 'sentry-plugin',
  parallel: true,
  dependsOn: ['auth0-plugin'],
  async setup(nuxtApp) {
    const { $config, $router, vueApp } = nuxtApp
    const auth = nuxtApp.$auth as Auth0Wrapper
    const user = await auth.client.getUser()

    if ($config.public.SENTRY_ENABLED) {
      Sentry.init({
        app: vueApp,
        dsn: $config.public.SENTRY_DSN,
        environment: $config.public.SENTRY_ENVIRONMENT,
        release: $config.public.SENTRY_RELEASE,
        initialScope: (scope) => {
          scope.setUser(getUser(user))
          return scope
        },
        integrations: [
          Sentry.browserTracingIntegration({
            router: $router,
          }),
          Sentry.replayIntegration({
            maskAllText: false,
            maskAllInputs: false,
            blockAllMedia: false,
          }),
          Sentry.replayCanvasIntegration(),
        ],
        trackComponents: true,
        sampleRate: 1,
        tracesSampleRate: 1,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1,
      })
    }
  },
})
