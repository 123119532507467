import { storeToRefs } from 'pinia'
import { useNuxtApp, useRoute } from 'nuxt/app'
import { filterMenuByRoles, getFirstUrlByRoles } from '~/constants/menu'
import { useGeneralStore } from '~/stores/general'
import type { MenuDataType } from '~/types/menu.type'

const useMenu = () => {
  const { $authorization } = useNuxtApp()
  const route = useRoute()

  const generalStore = useGeneralStore()
  const { isAdminMode } = storeToRefs(generalStore)

  const filterMenu = (typeMenu: MenuDataType) => {
    return filterMenuByRoles(typeMenu, $authorization)
  }

  const getFirstUrl = () => {
    return getFirstUrlByRoles(isAdminMode.value, $authorization, route.path)
  }

  return { isAdminMode, filterMenu, getFirstUrl }
}

export default useMenu
