const handleOutsideClick = (el, binding, event) => {
  event.stopPropagation()
  const { handler, exclude = [] } = binding.value
  let clickedOnExcludedEl = false
  exclude.forEach((refName) => {
    if (!clickedOnExcludedEl) {
      clickedOnExcludedEl = event.target.id === refName
    }
  })
  if (!el.contains(event.target) && !clickedOnExcludedEl) {
    binding.instance[handler]()
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('closable', {
    mounted(el, binding) {
      document.addEventListener('click', (e) => handleOutsideClick(el, binding, e))
      document.addEventListener('touchstart', (e) => handleOutsideClick(el, binding, e))
    },

    unmounted() {
      document.removeEventListener('click', handleOutsideClick)
      document.removeEventListener('touchstart', handleOutsideClick)
    },
  })
})
