import type { Auth0Client } from '@auth0/auth0-spa-js'
import useAuth from '~/composables/useAuth'

export class Auth0Wrapper {
  private readonly auth: ReturnType<typeof useAuth>
  private readonly _client: Auth0Client

  constructor(client: Auth0Client, auth: ReturnType<typeof useAuth>) {
    this._client = client
    this.auth = auth
  }

  get client(): Auth0Client {
    return this._client
  }

  async logout() {
    this.auth.setToken(null)
    this.auth.setUser(null)
    await this._client.logout({ logoutParams: { returnTo: window.location.origin } })
    await navigateTo('/login', { replace: true })
  }
}
