<template>
  <PageSection class="flex flex-grow size-full flex-col pt-24">
    <div
      class="text-p-yellow-50 pb-14 text-7xl font-bold leading-none text-center"
      data-testid="title-error-message"
    >
      {{ title }}
    </div>
    <div
      class="w-[723px] self-center pb-7 text-center text-xl font-semibold leading-7 text-white"
      data-testid="message-error-message"
    >
      {{ message }} please contact us at
      <a
        data-testid="email-error-message"
        class="text-p-yellow-500 underline"
        href="mailto:support.terminal@benchmarkminerals.com"
      >
        support.terminal@benchmarkminerals.com
      </a>
    </div>
    <nuxt-link v-if="showReturnToHomePage" :to="urlToDashboard" class="self-center">
      <button
        type="button"
        class="bg-p-yellow-500 rounded-sm px-4 py-1.5 text-sm font-bold leading-5"
        data-testid="button-error-message"
      >
        Return to homepage
      </button>
    </nuxt-link>
  </PageSection>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import useMenu from '~/composables/useMenu'

const { getFirstUrl } = useMenu()

withDefaults(
  defineProps<{
    title?: string
    message?: string
    showReturnToHomePage?: boolean
  }>(),
  {
    title: '',
    message: '',
    showReturnToHomePage: true,
  },
)

const urlToDashboard = computed(() => getFirstUrl())
</script>
