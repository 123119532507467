<template>
  <div
    :class="[
      {
        'account-button-active': showPopup,
      },
    ]"
    class="account-button group relative inline-block cursor-pointer select-none rounded-xl"
  >
    <div
      id="dropdown"
      ref="button"
      class="inline-flex items-center gap-1 px-2 py-1.5"
      @click="showPopup = !showPopup"
    >
      <UserAvatar class="h-3.5 w-3.5 pointer-events-none text-p-yellow-600" />
      <span class="text-base text-white pointer-events-none">Account</span>
    </div>
    <transition name="fade">
      <div
        v-if="showPopup"
        v-closable="{
          exclude: ['dropdown'],
          handler: 'onClose',
        }"
        class="absolute right-0 z-20"
      >
        <div
          class="account-button-container mt-3 w-max min-w-[150px] rounded-xl px-3 py-2 after:absolute after:-top-5 after:left-3/4 after:border-[10px] after:border-solid after:border-b-[#18283E] after:border-l-transparent after:border-r-transparent after:border-t-transparent after:content-['']"
        >
          <nuxt-link v-if="showAccountDetailsLink" class="flex" to="/account/">
            <div class="text-white">Account Details</div>
          </nuxt-link>
          <div class="text-p-yellow-500" @click.prevent="handleLogout()">Log out</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import UserAvatar from '~/assets/icons/user-avatar.svg'

withDefaults(
  defineProps<{
    showAccountDetailsLink?: boolean
  }>(),
  {
    showAccountDetailsLink: true,
  },
)

const showPopup = ref(false)

const authWrapper = useNuxtApp().$auth

const handleLogout = async () => {
  await authWrapper.logout()
}

const onClose = () => {
  showPopup.value = false
}

defineExpose({
  onClose,
})
</script>

<style scoped>
.account-button:hover {
  background: linear-gradient(0deg, rgb(255 255 255 / 5%), rgb(255 255 255 / 5%)), #071830;
}

.account-button-container {
  background: linear-gradient(0deg, rgb(255 255 255 / 7%), rgb(255 255 255 / 7%)), #071830;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  backdrop-filter: blur(2px);
}

.account-button-active {
  background: linear-gradient(0deg, rgb(255 255 255 / 16%), rgb(255 255 255 / 16%)), #071830 !important;
  box-shadow: inset 0 4px 4px rgb(0 0 0 / 25%) !important;
}
</style>
