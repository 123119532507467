import { defineStore } from 'pinia'
import { ValueType, INITIAL_VALUE_TYPE } from '~/constants/general'

interface State {
  isLoading: boolean
  isFixedSideNav: boolean
  isOpenSideNav: boolean
  isFullScreenMode: boolean
  isAdminMode: boolean
  weightedUnweighted: ValueType
}

export const useGeneralStore = defineStore('general', {
  state: (): State => ({
    isLoading: false,
    isFixedSideNav: true,
    isOpenSideNav: true,
    isFullScreenMode: false,
    isAdminMode: false,
    weightedUnweighted: INITIAL_VALUE_TYPE,
  }),
  actions: {
    toggleSideNav() {
      this.isFixedSideNav = !this.isFixedSideNav
      this.isOpenSideNav = this.isFixedSideNav
    },

    changeFullScreenMode(isFullScreenMode) {
      this.isFullScreenMode = isFullScreenMode
    },

    setIsAdminMode(isAdminMode) {
      this.isAdminMode = isAdminMode
    },

    setIsLoading(value) {
      this.isLoading = value
    },

    setWeightedUnweighted(value: ValueType) {
      this.weightedUnweighted = value
    },
  },
})
