import intersection from 'lodash/intersection'
import useAuth from '~/composables/useAuth'
import type { User } from '~/constants/general'
import { Auth } from '~/constants/general'
import { ROLES } from '~/constants/roles'
import { AdminMenuData } from '~/constants/menu'

export class Authorization {
  private readonly auth: ReturnType<typeof useAuth>
  private readonly user: ComputedRef<User>

  constructor(auth: ReturnType<typeof useAuth>) {
    this.auth = auth
    this.user = computed(() => this.auth.user.value)
  }

  isAdmin(): boolean {
    if (!this.hasUser()) return false
    return this.user.value[Auth.auth0RolesProperty].roles.includes('Admin')
  }

  hasAccess(roles: ROLES[] = []): boolean {
    if (!this.hasUser()) return false
    if (this.isAdmin()) return true

    return Boolean(intersection(roles, this.user.value[Auth.auth0RolesProperty].roles).length)
  }

  hasLithiumRole(): boolean {
    return this.hasAccess([ROLES.LITHIUM_READ])
  }

  hasAdminPermission(): boolean {
    const adminRoles = Object.keys(AdminMenuData)
      .map((key) => AdminMenuData[key].roles)
      .flat()
    return this.hasAccess(adminRoles)
  }

  hasSingleRole(role: ROLES): boolean {
    if (!this.hasUser()) return false
    return (
      this.user.value[Auth.auth0RolesProperty].roles.length === 1 &&
      this.user.value[Auth.auth0RolesProperty].roles[0] === role
    )
  }

  onlyDocsRole(): boolean {
    return this.hasSingleRole(ROLES.DOCS_READ)
  }

  hasOnlyLithiumReadRole(): boolean {
    if (!this.hasUser()) return false

    const readRoleRegexp = /^([^:]+):read$/

    const roles: ROLES[] = this.user.value[Auth.auth0RolesProperty].roles
    const allReadRoles = roles.filter((role) => readRoleRegexp.test(role))

    return allReadRoles.length === 1 && allReadRoles[0] === ROLES.LITHIUM_READ
  }

  hasDownloadPermission(): boolean {
    return this.hasAccess([ROLES.DOWNLOAD])
  }

  hasEmptyRoles(): boolean {
    if (!this.hasUser()) return true
    return this.user.value[Auth.auth0RolesProperty].roles.length === 0
  }

  private hasUser(): boolean {
    return !!this.user.value
  }
}
