import { Form, Field, ErrorMessage, defineRule, configure } from 'vee-validate'
import { required, confirmed, min, email } from '@vee-validate/rules'
import { localize } from '@vee-validate/i18n'
import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('VForm', Form)
  nuxtApp.vueApp.component('VField', Field)
  nuxtApp.vueApp.component('VErrorMessage', ErrorMessage)

  defineRule('required', required)
  defineRule('confirmed', confirmed)
  defineRule('min', min)

  defineRule('email', (value) => email(value))

  defineRule('verify_password', (value) => {
    const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s])(?=.{8,})/
    return strongRegex.test(value)
  })

  configure({
    generateMessage: localize('en', {
      messages: {
        required: '{field} cannot be empty',
        minmax: '{field} must be between {min} and {max}',
        min: '{field} must has a minimum length of {length}',
        confirmed: '{field} must match',
        verify_password:
          'The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character',
        email: 'The email is invalid',
      },
    }),
  })
})
