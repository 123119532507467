import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin((nuxtApp) => {
  const toast = nuxtApp.$toastService

  const toastErrors = (err) => {
    const { $config } = nuxtApp
    const { NODE_ENV } = $config.public
    if (NODE_ENV === 'development') {
      if (Array.isArray(err)) {
        err.forEach((error) => toast.error(error.toString()))
      } else {
        toast.error(err.toString())
      }
    }
  }

  return {
    provide: {
      toastErrors,
    },
  }
})
