import validate from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/opt/buildhome/repo/src/middleware/auth.global.ts";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "auth-admin": () => import("/opt/buildhome/repo/src/middleware/auth-admin.js"),
  "first-url-redirect": () => import("/opt/buildhome/repo/src/middleware/first-url-redirect.js"),
  roles: () => import("/opt/buildhome/repo/src/middleware/roles.js")
}