import payload_plugin_qLmFnukI99 from "/opt/buildhome/repo/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/opt/buildhome/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/opt/buildhome/repo/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import amcharts_45W4YpQ3sq from "/opt/buildhome/repo/src/plugins/amcharts.js";
import auth0_client_UZnw2QnCGK from "/opt/buildhome/repo/src/plugins/auth0.client.ts";
import authorization_client_sEETF2R94a from "/opt/buildhome/repo/src/plugins/authorization.client.ts";
import axios_client_ccTz6SHL5W from "/opt/buildhome/repo/src/plugins/axios.client.ts";
import chartjs_2aXDgxyX7N from "/opt/buildhome/repo/src/plugins/chartjs.js";
import dateRangePicker_client_N7p92V1ipx from "/opt/buildhome/repo/src/plugins/dateRangePicker.client.js";
import directives_zWEA8pZnau from "/opt/buildhome/repo/src/plugins/directives.js";
import sentry_client_config_cPKJD40CT4 from "/opt/buildhome/repo/src/plugins/sentry.client.config.ts";
import toast_service_client_52aPOL2mxx from "/opt/buildhome/repo/src/plugins/toast-service.client.js";
import toastErrors_q2Tx9adpfN from "/opt/buildhome/repo/src/plugins/toastErrors.js";
import vee_validate_client_45gWhfAHXv from "/opt/buildhome/repo/src/plugins/vee-validate.client.js";
import vueDOMPurifyHTML_client_MYTI5D1QKf from "/opt/buildhome/repo/src/plugins/vueDOMPurifyHTML.client.js";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  amcharts_45W4YpQ3sq,
  auth0_client_UZnw2QnCGK,
  authorization_client_sEETF2R94a,
  axios_client_ccTz6SHL5W,
  chartjs_2aXDgxyX7N,
  dateRangePicker_client_N7p92V1ipx,
  directives_zWEA8pZnau,
  sentry_client_config_cPKJD40CT4,
  toast_service_client_52aPOL2mxx,
  toastErrors_q2Tx9adpfN,
  vee_validate_client_45gWhfAHXv,
  vueDOMPurifyHTML_client_MYTI5D1QKf
]