import { defineNuxtRouteMiddleware, navigateTo, useNuxtApp } from 'nuxt/app'
import { getFirstUrlByRoles } from '~/constants/menu'
import useAuth from '~/composables/useAuth'

export default defineNuxtRouteMiddleware((to) => {
  const { $authorization } = useNuxtApp()
  const { token } = useAuth()

  const isAuthenticated = token.value !== null

  const isLogin = to.path === '/login'
  const publicPages = ['request-access']

  if (publicPages.includes(to.name as string)) return

  if (!isAuthenticated && isLogin) return
  if (!isAuthenticated && !isLogin) return navigateTo({ path: '/login' }, { replace: true })

  if ($authorization.hasEmptyRoles()) {
    abortNavigation({ statusCode: 401 })
    return false
  }

  if (isAuthenticated && isLogin) {
    const routesArray = to.path.split('/')
    const isAdminMode = routesArray.includes('admin')
    const url = getFirstUrlByRoles(isAdminMode, $authorization, to.path)
    return navigateTo({ path: url }, { replace: true })
  }
})
