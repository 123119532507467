import { default as accountZvnRixdEIVMeta } from "/opt/buildhome/repo/src/pages/account.vue?macro=true";
import { default as denormalizedKAfGMES2eWMeta } from "/opt/buildhome/repo/src/pages/admin/anodes/denormalized.vue?macro=true";
import { default as price_45indexOvQ6BDafhdMeta } from "/opt/buildhome/repo/src/pages/admin/anodes/price-index.vue?macro=true";
import { default as pricesbiAfNxwvYgMeta } from "/opt/buildhome/repo/src/pages/admin/anodes/prices.vue?macro=true";
import { default as price_45indexqXyFyPXD27Meta } from "/opt/buildhome/repo/src/pages/admin/battery-cells/price-index.vue?macro=true";
import { default as pricesmigE3ASuCcMeta } from "/opt/buildhome/repo/src/pages/admin/battery-cells/prices.vue?macro=true";
import { default as raw_45material_45indexQIWrLEOtv2Meta } from "/opt/buildhome/repo/src/pages/admin/battery-cells/raw-material-index.vue?macro=true";
import { default as price_45indexKRwszb2W6WMeta } from "/opt/buildhome/repo/src/pages/admin/black-mass/price-index.vue?macro=true";
import { default as pricesR7cPfoeaPRMeta } from "/opt/buildhome/repo/src/pages/admin/black-mass/prices.vue?macro=true";
import { default as price_45indexK5RchWosyKMeta } from "/opt/buildhome/repo/src/pages/admin/cathodes/price-index.vue?macro=true";
import { default as pricespgd4wfTU8qMeta } from "/opt/buildhome/repo/src/pages/admin/cathodes/prices.vue?macro=true";
import { default as price_45indexg8JlVK13tkMeta } from "/opt/buildhome/repo/src/pages/admin/cobalt/price-index.vue?macro=true";
import { default as priceswLSdmQMepUMeta } from "/opt/buildhome/repo/src/pages/admin/cobalt/prices.vue?macro=true";
import { default as pricesdGGyc8gjKRMeta } from "/opt/buildhome/repo/src/pages/admin/electrolyte/prices.vue?macro=true";
import { default as indexqSfMZI7JTjMeta } from "/opt/buildhome/repo/src/pages/admin/index.vue?macro=true";
import { default as costslkOSX3ca9EMeta } from "/opt/buildhome/repo/src/pages/admin/lithium/costs.vue?macro=true";
import { default as pricing_45index9aFGU9AHdCMeta } from "/opt/buildhome/repo/src/pages/admin/lithium/crud/pricing-index.vue?macro=true";
import { default as pricingCDzvo3WMQxMeta } from "/opt/buildhome/repo/src/pages/admin/lithium/crud/pricing.vue?macro=true";
import { default as daily_45price_45indicator7Ges6vgClfMeta } from "/opt/buildhome/repo/src/pages/admin/lithium/daily-price-indicator.vue?macro=true";
import { default as ev_45salesFbRWvzsVhYMeta } from "/opt/buildhome/repo/src/pages/admin/lithium/ev-sales.vue?macro=true";
import { default as forecastwjKBKKgzEjMeta } from "/opt/buildhome/repo/src/pages/admin/lithium/forecast.vue?macro=true";
import { default as price_45indexlvV7QQIPFpMeta } from "/opt/buildhome/repo/src/pages/admin/lithium/price-index.vue?macro=true";
import { default as prices_45forecastCACtFerDbQMeta } from "/opt/buildhome/repo/src/pages/admin/lithium/prices-forecast.vue?macro=true";
import { default as pricesk8Ql14asEQMeta } from "/opt/buildhome/repo/src/pages/admin/lithium/prices.vue?macro=true";
import { default as supply_45raw_45materialMb9iA8oj4YMeta } from "/opt/buildhome/repo/src/pages/admin/lithium/supply-raw-material.vue?macro=true";
import { default as prices1PgYI3swo9Meta } from "/opt/buildhome/repo/src/pages/admin/manganese/prices.vue?macro=true";
import { default as price_45index5Lyo5lYHpJMeta } from "/opt/buildhome/repo/src/pages/admin/natural-graphite/price-index.vue?macro=true";
import { default as pricesoijtS0Uh3JMeta } from "/opt/buildhome/repo/src/pages/admin/natural-graphite/prices.vue?macro=true";
import { default as price_45indexKwgvwV9ZNoMeta } from "/opt/buildhome/repo/src/pages/admin/nickel/price-index.vue?macro=true";
import { default as pricesaHvZM9iPHMMeta } from "/opt/buildhome/repo/src/pages/admin/nickel/prices.vue?macro=true";
import { default as pricesy7YV4AZ3rYMeta } from "/opt/buildhome/repo/src/pages/admin/rare-earths/prices.vue?macro=true";
import { default as price_45indexMwcYQMGFs9Meta } from "/opt/buildhome/repo/src/pages/admin/synthetic-graphite/price-index.vue?macro=true";
import { default as pricesF1mgdBNWZqMeta } from "/opt/buildhome/repo/src/pages/admin/synthetic-graphite/prices.vue?macro=true";
import { default as managementYSo1Ip6YIUMeta } from "/opt/buildhome/repo/src/pages/admin/users/management.vue?macro=true";
import { default as home1apSPTflPnMeta } from "/opt/buildhome/repo/src/pages/home.vue?macro=true";
import { default as indexhGpynX1cdDMeta } from "/opt/buildhome/repo/src/pages/index.vue?macro=true";
import { default as _91type_93DuaTNgpbgJMeta } from "/opt/buildhome/repo/src/pages/lithium/costs/[product]/[type].vue?macro=true";
import { default as costs2wjn2187MUMeta } from "/opt/buildhome/repo/src/pages/lithium/costs.vue?macro=true";
import { default as demandU2G9szKb02Meta } from "/opt/buildhome/repo/src/pages/lithium/demand.vue?macro=true";
import { default as stock_45marketCnJcGKqYilMeta } from "/opt/buildhome/repo/src/pages/lithium/equity-data/stock-market.vue?macro=true";
import { default as _91tab_93PTkOjJQpO5Meta } from "/opt/buildhome/repo/src/pages/lithium/overview/[tab].vue?macro=true";
import { default as overviewlrKfTwAzfrMeta } from "/opt/buildhome/repo/src/pages/lithium/overview.vue?macro=true";
import { default as forecastsBzOb0AuZmpMeta } from "/opt/buildhome/repo/src/pages/lithium/prices/forecasts.vue?macro=true";
import { default as indexHQ5yQ6mxDTMeta } from "/opt/buildhome/repo/src/pages/lithium/prices/index.vue?macro=true";
import { default as priceindexZjnCyEdsnpMeta } from "/opt/buildhome/repo/src/pages/lithium/prices/priceindex.vue?macro=true";
import { default as pricesjIWco6EpRAMeta } from "/opt/buildhome/repo/src/pages/lithium/prices/prices.vue?macro=true";
import { default as rangecTJdKNKIQmMeta } from "/opt/buildhome/repo/src/pages/lithium/prices/range.vue?macro=true";
import { default as tableUAogMCxVtfMeta } from "/opt/buildhome/repo/src/pages/lithium/prices/table.vue?macro=true";
import { default as utilsNJoNnLKgsrMeta } from "/opt/buildhome/repo/src/pages/lithium/prices/utils.ts?macro=true";
import { default as pricesNv85cjpT43Meta } from "/opt/buildhome/repo/src/pages/lithium/prices.vue?macro=true";
import { default as _91id_936CZvzpp4KTMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/chemical/companies/[id].vue?macro=true";
import { default as overview7WQIELJehLMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/chemical/companies/overview.vue?macro=true";
import { default as _91id_93cgKHC1IK2yMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/chemical/countries/[id].vue?macro=true";
import { default as overviewyXb82MGdAvMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/chemical/countries/overview.vue?macro=true";
import { default as _91id_93gdbemIi4TMMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/chemical/feedstocks/[id].vue?macro=true";
import { default as overviewNBtUNK9ywCMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/chemical/feedstocks/overview.vue?macro=true";
import { default as _91id_93UE9uGnKmILMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/chemical/plants/[id].vue?macro=true";
import { default as overviewdLzXKUQ7ZVMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/chemical/plants/overview.vue?macro=true";
import { default as _91id_93mttDDEXKyyMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/chemical/products/[id].vue?macro=true";
import { default as overviewy2X92ZuryiMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/chemical/products/overview.vue?macro=true";
import { default as _91id_93aodi8uIP8AMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/chemical/regions/[id].vue?macro=true";
import { default as overview9K8vROu4bJMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/chemical/regions/overview.vue?macro=true";
import { default as chemicalSJoXcMkRTXMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/chemical.vue?macro=true";
import { default as _91id_93xGks76qzheMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/mined/companies/[id].vue?macro=true";
import { default as overviewo8CNSVrxdyMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/mined/companies/overview.vue?macro=true";
import { default as _91id_93h9C5wItvJjMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/mined/countries/[id].vue?macro=true";
import { default as overview6jI4yiVBNoMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/mined/countries/overview.vue?macro=true";
import { default as _91id_93ZBadZ0JvEtMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/mined/mines/[id].vue?macro=true";
import { default as overviewuAN8WiUsdaMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/mined/mines/overview.vue?macro=true";
import { default as _91id_93s554QL0hUpMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/mined/ore-type/[id].vue?macro=true";
import { default as overviewaCmcZfalUHMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/mined/ore-type/overview.vue?macro=true";
import { default as _91id_93j10qMJMSfAMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/mined/regions/[id].vue?macro=true";
import { default as overviewrsg2o5kyivMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/mined/regions/overview.vue?macro=true";
import { default as minedmCYBtjiFNqMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/mined.vue?macro=true";
import { default as loginQTRV5Xkfi8Meta } from "/opt/buildhome/repo/src/pages/login.vue?macro=true";
import { default as request_45accessVIfM336M1rMeta } from "/opt/buildhome/repo/src/pages/request-access.vue?macro=true";
export default [
  {
    name: "account",
    path: "/account",
    component: () => import("/opt/buildhome/repo/src/pages/account.vue")
  },
  {
    name: "admin-anodes-denormalized",
    path: "/admin/anodes/denormalized",
    meta: denormalizedKAfGMES2eWMeta || {},
    component: () => import("/opt/buildhome/repo/src/pages/admin/anodes/denormalized.vue")
  },
  {
    name: "admin-anodes-price-index",
    path: "/admin/anodes/price-index",
    component: () => import("/opt/buildhome/repo/src/pages/admin/anodes/price-index.vue")
  },
  {
    name: "admin-anodes-prices",
    path: "/admin/anodes/prices",
    component: () => import("/opt/buildhome/repo/src/pages/admin/anodes/prices.vue")
  },
  {
    name: "admin-battery-cells-price-index",
    path: "/admin/battery-cells/price-index",
    component: () => import("/opt/buildhome/repo/src/pages/admin/battery-cells/price-index.vue")
  },
  {
    name: "admin-battery-cells-prices",
    path: "/admin/battery-cells/prices",
    component: () => import("/opt/buildhome/repo/src/pages/admin/battery-cells/prices.vue")
  },
  {
    name: "admin-battery-cells-raw-material-index",
    path: "/admin/battery-cells/raw-material-index",
    component: () => import("/opt/buildhome/repo/src/pages/admin/battery-cells/raw-material-index.vue")
  },
  {
    name: "admin-black-mass-price-index",
    path: "/admin/black-mass/price-index",
    component: () => import("/opt/buildhome/repo/src/pages/admin/black-mass/price-index.vue")
  },
  {
    name: "admin-black-mass-prices",
    path: "/admin/black-mass/prices",
    component: () => import("/opt/buildhome/repo/src/pages/admin/black-mass/prices.vue")
  },
  {
    name: "admin-cathodes-price-index",
    path: "/admin/cathodes/price-index",
    component: () => import("/opt/buildhome/repo/src/pages/admin/cathodes/price-index.vue")
  },
  {
    name: "admin-cathodes-prices",
    path: "/admin/cathodes/prices",
    component: () => import("/opt/buildhome/repo/src/pages/admin/cathodes/prices.vue")
  },
  {
    name: "admin-cobalt-price-index",
    path: "/admin/cobalt/price-index",
    component: () => import("/opt/buildhome/repo/src/pages/admin/cobalt/price-index.vue")
  },
  {
    name: "admin-cobalt-prices",
    path: "/admin/cobalt/prices",
    component: () => import("/opt/buildhome/repo/src/pages/admin/cobalt/prices.vue")
  },
  {
    name: "admin-electrolyte-prices",
    path: "/admin/electrolyte/prices",
    component: () => import("/opt/buildhome/repo/src/pages/admin/electrolyte/prices.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexqSfMZI7JTjMeta || {},
    component: () => import("/opt/buildhome/repo/src/pages/admin/index.vue")
  },
  {
    name: "admin-lithium-costs",
    path: "/admin/lithium/costs",
    component: () => import("/opt/buildhome/repo/src/pages/admin/lithium/costs.vue")
  },
  {
    name: "admin-lithium-crud-pricing-index",
    path: "/admin/lithium/crud/pricing-index",
    component: () => import("/opt/buildhome/repo/src/pages/admin/lithium/crud/pricing-index.vue")
  },
  {
    name: "admin-lithium-crud-pricing",
    path: "/admin/lithium/crud/pricing",
    component: () => import("/opt/buildhome/repo/src/pages/admin/lithium/crud/pricing.vue")
  },
  {
    name: "admin-lithium-daily-price-indicator",
    path: "/admin/lithium/daily-price-indicator",
    component: () => import("/opt/buildhome/repo/src/pages/admin/lithium/daily-price-indicator.vue")
  },
  {
    name: "admin-lithium-ev-sales",
    path: "/admin/lithium/ev-sales",
    component: () => import("/opt/buildhome/repo/src/pages/admin/lithium/ev-sales.vue")
  },
  {
    name: "admin-lithium-forecast",
    path: "/admin/lithium/forecast",
    component: () => import("/opt/buildhome/repo/src/pages/admin/lithium/forecast.vue")
  },
  {
    name: "admin-lithium-price-index",
    path: "/admin/lithium/price-index",
    component: () => import("/opt/buildhome/repo/src/pages/admin/lithium/price-index.vue")
  },
  {
    name: "admin-lithium-prices-forecast",
    path: "/admin/lithium/prices-forecast",
    component: () => import("/opt/buildhome/repo/src/pages/admin/lithium/prices-forecast.vue")
  },
  {
    name: "admin-lithium-prices",
    path: "/admin/lithium/prices",
    component: () => import("/opt/buildhome/repo/src/pages/admin/lithium/prices.vue")
  },
  {
    name: "admin-lithium-supply-raw-material",
    path: "/admin/lithium/supply-raw-material",
    component: () => import("/opt/buildhome/repo/src/pages/admin/lithium/supply-raw-material.vue")
  },
  {
    name: "admin-manganese-prices",
    path: "/admin/manganese/prices",
    component: () => import("/opt/buildhome/repo/src/pages/admin/manganese/prices.vue")
  },
  {
    name: "admin-natural-graphite-price-index",
    path: "/admin/natural-graphite/price-index",
    component: () => import("/opt/buildhome/repo/src/pages/admin/natural-graphite/price-index.vue")
  },
  {
    name: "admin-natural-graphite-prices",
    path: "/admin/natural-graphite/prices",
    component: () => import("/opt/buildhome/repo/src/pages/admin/natural-graphite/prices.vue")
  },
  {
    name: "admin-nickel-price-index",
    path: "/admin/nickel/price-index",
    component: () => import("/opt/buildhome/repo/src/pages/admin/nickel/price-index.vue")
  },
  {
    name: "admin-nickel-prices",
    path: "/admin/nickel/prices",
    component: () => import("/opt/buildhome/repo/src/pages/admin/nickel/prices.vue")
  },
  {
    name: "admin-rare-earths-prices",
    path: "/admin/rare-earths/prices",
    component: () => import("/opt/buildhome/repo/src/pages/admin/rare-earths/prices.vue")
  },
  {
    name: "admin-synthetic-graphite-price-index",
    path: "/admin/synthetic-graphite/price-index",
    component: () => import("/opt/buildhome/repo/src/pages/admin/synthetic-graphite/price-index.vue")
  },
  {
    name: "admin-synthetic-graphite-prices",
    path: "/admin/synthetic-graphite/prices",
    component: () => import("/opt/buildhome/repo/src/pages/admin/synthetic-graphite/prices.vue")
  },
  {
    name: "admin-users-management",
    path: "/admin/users/management",
    component: () => import("/opt/buildhome/repo/src/pages/admin/users/management.vue")
  },
  {
    name: "home",
    path: "/home",
    component: () => import("/opt/buildhome/repo/src/pages/home.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexhGpynX1cdDMeta || {},
    component: () => import("/opt/buildhome/repo/src/pages/index.vue")
  },
  {
    name: "lithium-costs",
    path: "/lithium/costs",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/costs.vue"),
    children: [
  {
    name: "lithium-costs-product-type",
    path: ":product()/:type()",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/costs/[product]/[type].vue")
  }
]
  },
  {
    name: "lithium-demand",
    path: "/lithium/demand",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/demand.vue")
  },
  {
    name: "lithium-equity-data-stock-market",
    path: "/lithium/equity-data/stock-market",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/equity-data/stock-market.vue")
  },
  {
    name: "lithium-overview",
    path: "/lithium/overview",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/overview.vue"),
    children: [
  {
    name: "lithium-overview-tab",
    path: ":tab()",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/overview/[tab].vue")
  }
]
  },
  {
    name: pricesNv85cjpT43Meta?.name,
    path: "/lithium/prices",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/prices.vue"),
    children: [
  {
    name: "lithium-prices-forecasts",
    path: "forecasts",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/prices/forecasts.vue")
  },
  {
    name: "lithium-prices",
    path: "",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/prices/index.vue")
  },
  {
    name: "lithium-prices-priceindex",
    path: "priceindex",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/prices/priceindex.vue")
  },
  {
    name: "lithium-prices-prices",
    path: "prices",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/prices/prices.vue")
  },
  {
    name: "lithium-prices-range",
    path: "range",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/prices/range.vue")
  },
  {
    name: "lithium-prices-table",
    path: "table",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/prices/table.vue")
  },
  {
    name: "lithium-prices-utils",
    path: "utils",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/prices/utils.ts")
  }
]
  },
  {
    name: "lithium-supply-chemical",
    path: "/lithium/supply/chemical",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/chemical.vue"),
    children: [
  {
    name: "lithium-supply-chemical-companies-id",
    path: "companies/:id()",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/chemical/companies/[id].vue")
  },
  {
    name: "lithium-supply-chemical-companies-overview",
    path: "companies/overview",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/chemical/companies/overview.vue")
  },
  {
    name: "lithium-supply-chemical-countries-id",
    path: "countries/:id()",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/chemical/countries/[id].vue")
  },
  {
    name: "lithium-supply-chemical-countries-overview",
    path: "countries/overview",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/chemical/countries/overview.vue")
  },
  {
    name: "lithium-supply-chemical-feedstocks-id",
    path: "feedstocks/:id()",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/chemical/feedstocks/[id].vue")
  },
  {
    name: "lithium-supply-chemical-feedstocks-overview",
    path: "feedstocks/overview",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/chemical/feedstocks/overview.vue")
  },
  {
    name: "lithium-supply-chemical-plants-id",
    path: "plants/:id()",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/chemical/plants/[id].vue")
  },
  {
    name: "lithium-supply-chemical-plants-overview",
    path: "plants/overview",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/chemical/plants/overview.vue")
  },
  {
    name: "lithium-supply-chemical-products-id",
    path: "products/:id()",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/chemical/products/[id].vue")
  },
  {
    name: "lithium-supply-chemical-products-overview",
    path: "products/overview",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/chemical/products/overview.vue")
  },
  {
    name: "lithium-supply-chemical-regions-id",
    path: "regions/:id()",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/chemical/regions/[id].vue")
  },
  {
    name: "lithium-supply-chemical-regions-overview",
    path: "regions/overview",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/chemical/regions/overview.vue")
  }
]
  },
  {
    name: "lithium-supply-mined",
    path: "/lithium/supply/mined",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/mined.vue"),
    children: [
  {
    name: "lithium-supply-mined-companies-id",
    path: "companies/:id()",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/mined/companies/[id].vue")
  },
  {
    name: "lithium-supply-mined-companies-overview",
    path: "companies/overview",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/mined/companies/overview.vue")
  },
  {
    name: "lithium-supply-mined-countries-id",
    path: "countries/:id()",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/mined/countries/[id].vue")
  },
  {
    name: "lithium-supply-mined-countries-overview",
    path: "countries/overview",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/mined/countries/overview.vue")
  },
  {
    name: "lithium-supply-mined-mines-id",
    path: "mines/:id()",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/mined/mines/[id].vue")
  },
  {
    name: "lithium-supply-mined-mines-overview",
    path: "mines/overview",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/mined/mines/overview.vue")
  },
  {
    name: "lithium-supply-mined-ore-type-id",
    path: "ore-type/:id()",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/mined/ore-type/[id].vue")
  },
  {
    name: "lithium-supply-mined-ore-type-overview",
    path: "ore-type/overview",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/mined/ore-type/overview.vue")
  },
  {
    name: "lithium-supply-mined-regions-id",
    path: "regions/:id()",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/mined/regions/[id].vue")
  },
  {
    name: "lithium-supply-mined-regions-overview",
    path: "regions/overview",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/mined/regions/overview.vue")
  }
]
  },
  {
    name: "login",
    path: "/login",
    meta: loginQTRV5Xkfi8Meta || {},
    component: () => import("/opt/buildhome/repo/src/pages/login.vue")
  },
  {
    name: "request-access",
    path: "/request-access",
    meta: request_45accessVIfM336M1rMeta || {},
    component: () => import("/opt/buildhome/repo/src/pages/request-access.vue")
  }
]