import { createAuth0Client } from '@auth0/auth0-spa-js'
import { Auth0Wrapper } from '~/utils/core/auth0-wrapper'
import useAuth from '~/composables/useAuth'

export default defineNuxtPlugin({
  name: 'auth0-plugin',
  parallel: true,
  async setup(nuxtApp) {
    const { AUTH0_CLIENT_ID, AUTH0_AUDIENCE, AUTH0_DOMAIN, AUTH0_MAX_AGE } = nuxtApp.$config.public

    const auth0Client = await createAuth0Client({
      useRefreshTokens: true,
      domain: AUTH0_DOMAIN,
      clientId: AUTH0_CLIENT_ID,
      cacheLocation: 'localstorage',
      authorizationParams: {
        redirect_uri: `${window.location.origin}/login`,
        audience: AUTH0_AUDIENCE,
        scope: 'openid profile email offline_access',
        max_age: AUTH0_MAX_AGE,
      },
    })

    const auth0Wrapper = new Auth0Wrapper(auth0Client, useAuth())

    return {
      provide: {
        auth: auth0Wrapper,
      },
    }
  },
})
