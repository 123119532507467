import { useNuxtApp } from 'nuxt/app'
import { useLocalStorage } from '@vueuse/core'
import type { User } from '~/constants/general'

const userLocalStorage = useLocalStorage('user', null)
const tokenLocalStorage = useLocalStorage('token', null)

const useAuth = () => {
  const getAuth = () => {
    return useNuxtApp().$auth
  }

  const setUser = (value: User) => {
    userLocalStorage.value = JSON.stringify(value)
  }

  const setToken = (value: string) => {
    tokenLocalStorage.value = value
  }

  const user = computed((): User => JSON.parse(userLocalStorage.value))

  const token = computed((): string => tokenLocalStorage.value)

  return {
    getAuth,
    user,
    setUser,
    token,
    setToken,
  }
}

export default useAuth
