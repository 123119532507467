import Toast, { POSITION, useToast } from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(Toast, {
    hideProgressBar: true,
    position: POSITION.TOP_RIGHT,
    duration: 5000,
  })

  const toastService = useToast()

  return {
    provide: {
      toastService,
    },
  }
})
